import React from "react";

export const setLocalStorageItem = async (key, value) => {
  return new Promise((resolve) => {
    localStorage.setItem(key, JSON.stringify(value));
    resolve();
  });
};

export const getLocalStorageItem = async (key) => {
  return new Promise((resolve) => {
    const value = localStorage.getItem(key);
    resolve(value !== null && value !== "undefined" ? JSON.parse(value) : "");
  });
};

export const clearLocalStorage = async () => {
  return new Promise((resolve) => {
    localStorage.clear();
    resolve();
  });
};

export const getPath = (slug, path = "") => {
  return `/${slug}${path}`;
};

export const isNotNull = (val) => {
  return val !== null && val !== undefined && val !== "" && val !== "null";
};

export const renderTextAreaContent = (val) => {
  return val.split("\r\n").map((line, index) => {
    return (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    );
  });
};
