import React from "react";
import { useParams } from "react-router-dom";
import { PageContainer } from "../Components/Layout/PageContainer";
import { SectionContainer } from "../Components/Layout/SectionContainer";
import { PageTitle } from "../Components/Layout/Layout";
import { Button } from "../Components/Button/Button";
import { ErrorMessageBox } from "../Components/Form/ErrorMessageBox/ErrorMessageBox";
import { getHeaderFooter, getTrack } from "../Utils/Services";
import { TextInput } from "../Components/Form/TextInput/TextInput";
import { Form, Formik } from "formik";
import * as Yup from "yup";

export default function Track() {
  const formikRef = React.useRef();
  const { slug } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isReady, setIsReady] = React.useState(false);
  const [hasPageError, setHasPageError] = React.useState(false);
  const [pageErrorCode, setPageErrorCode] = React.useState(null);
  const [campaign, setCampaign] = React.useState({});
  const [header, setHeader] = React.useState([]);
  const [footer, setFooter] = React.useState([]);
  const [isTracking, setIsTracking] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [hasError, setHasError] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    const headerFooterRes = await getHeaderFooter(slug);

    if (headerFooterRes.status === 200) {
      setCampaign(headerFooterRes?.data?.campaign);
      setHeader(headerFooterRes?.data?.headers);
      setFooter(headerFooterRes?.data?.footers);
      setIsReady(true);
    } else {
      setHasPageError(true);
      setPageErrorCode(headerFooterRes.status);
    }
    setIsLoading(false);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setIsLoading(true);
      setSubmitting(true);

      const res = await getTrack(
        slug,
        `?email=${values.email}&reference_number=${values.reference_number}`
      );

      if (res.status === 200) {
        setIsTracking(true);
        setData(res.data);
      } else if (res.status === 404) {
        setErrorMsg(
          "Sorry, no results found for your email address and tracking number."
        );
        setHasError(true);
      } else {
        setErrorMsg("Error");
        setHasError(true);
      }
    } catch (error) {
      console.error("Form submission error:", error);
    } finally {
      setSubmitting(false);
      setIsLoading(false);
    }
  };

  const initialValues = {
    email: "",
    reference_number: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email address.")
      .required(`This field is required`),
    reference_number: Yup.string().required(`This field is required`),
  });

  return (
    <PageContainer
      isLoading={isLoading}
      isReady={isReady}
      slug={slug}
      campaign={campaign}
      header={header}
      footer={footer}
      hasPageError={hasPageError}
      pageErrorCode={pageErrorCode}
    >
      <SectionContainer containerClassName="py-8 sm:py-12">
        <PageTitle
          title="Track & Trace"
          subtitle="Keep track on the progress of your reward from start to finish."
        />
        {isTracking ? (
          <div className="w-full max-w-screen-lg mx-auto flex flex-col py-5">
            <h2 className="text-2xl font-extrabold text-gray-900 mb-12">
              Fulfilment Status
            </h2>
            {data.length > 0
              ? data.map((item, index) => (
                  <div
                    className={`flex flex-col sm:flex-row justify-between gap-12 ${
                      data.length - 1 !== index ? "pb-6 mb-6 border-b" : ""
                    }`}
                  >
                    <div className="sm:w-1/3 space-y-7">
                      <div className="space-y-1">
                        <p className="text-base font-medium text-gray-900">
                          Reward
                        </p>
                        <p className="text-base text-gray-500">{item.reward}</p>
                      </div>
                      <div className="space-y-1">
                        <p className="text-base font-medium text-gray-900">
                          Status
                        </p>
                        <p className="text-base text-gray-500">{item.status}</p>
                      </div>
                      <div className="space-y-1">
                        <p className="text-base font-medium text-gray-900">
                          Reason
                        </p>
                        <p className="text-base text-gray-500">
                          {item.remark ?? "-"}
                        </p>
                      </div>
                    </div>
                    {item.tracking_number ? (
                      <div className="sm:w-1/2 space-y-4">
                        <div className="bg-gray-50 rounded-lg shadow-sm p-6 space-y-4">
                          <h3 className="text-lg font-bold text-gray-900">
                            Tracking Information
                          </h3>
                          <div className="space-y-1">
                            <p className="text-base font-medium text-gray-900">
                              Tracking Number
                            </p>
                            <p className="text-base text-gray-500">
                              {item.tracking_number}
                            </p>
                          </div>
                          <div className="space-y-1">
                            <p className="text-base font-medium text-gray-900">
                              Courier Company
                            </p>
                            <p className="text-base text-gray-500">
                              {item.courier}
                            </p>
                          </div>
                          <div className="space-y-1">
                            <p className="text-base font-medium text-gray-900">
                              Email Address
                            </p>
                            <p className="text-base text-gray-500">
                              {item.email}
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))
              : null}
            <div className="flex flex-col sm:flex-row justify-between gap-12 pb-6 mb-6 border-b last:border-none">
              <div className="sm:w-1/3 space-y-7"></div>
              <div className="sm:w-1/2 space-y-4">
                <Button
                  className="mt-12"
                  primary
                  label="Track Another"
                  onClick={() => window.location.reload()}
                />
              </div>
            </div>
          </div>
        ) : (
          <div
            className={`w-full max-w-screen-sm mx-auto space-y-6 ${
              hasError ? "pt-0" : "pt-5"
            } pb-5`}
          >
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ handleSubmit, isSubmitting }) => (
                <Form
                  onSubmit={handleSubmit}
                  className="flex flex-col gap-8 pt-2.5"
                >
                  {hasError && <ErrorMessageBox message={errorMsg} />}
                  <TextInput
                    className="w-full"
                    label="Email Address"
                    name="email"
                    type="email"
                    placeholder=""
                    required
                  />
                  <TextInput
                    className="w-full"
                    label="Reference Number"
                    name="reference_number"
                    type="text"
                    placeholder=""
                    required
                  />
                  <Button
                    primary
                    className=""
                    label="Track Now"
                    type="submit"
                    disabled={isSubmitting}
                  />
                </Form>
              )}
            </Formik>
          </div>
        )}
      </SectionContainer>
    </PageContainer>
  );
}
