import { FaSearch } from "react-icons/fa";

export const SearchInput = ({ value, setValue, handleSearch, placeholder }) => {
  return (
    <div className="flex items-center">
      <label className="sr-only">Search</label>
      <div className="relative w-full ">
        <div className="absolute inset-y-0 start-0 flex items-center ps-4 pointer-events-none">
          <FaSearch className="w-4 h-4 text-gray-500" />
        </div>
        <input
          className="bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded-s-lg block w-full ps-10 py-2 pr-4"
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder={placeholder}
        />
      </div>
      <button
        className="py-2 px-3 text-sm font-medium text-white bg-primary-700 border border-primary-700 rounded-e-lg"
        type="button"
        onClick={handleSearch}
      >
        Search
      </button>
    </div>
  );
};
