import React from "react";
import { Link, useParams } from "react-router-dom";
import { PageContainer } from "../Components/Layout/PageContainer";
import { SectionContainer } from "../Components/Layout/SectionContainer";
import {
  Accordion,
  AccordionItem,
  AccordionPanel,
} from "../Components/Accordion/Accordion";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import ReactPaginate from "react-paginate";
import { getHeaderFooter, getProducts } from "../Utils/Services";
import { isNotNull } from "../Utils/Helpers";
import { SearchInput } from "../Components/Form/SearchInput/SearchInput";

export default function Products() {
  const { slug } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isReady, setIsReady] = React.useState(false);
  const [hasPageError, setHasPageError] = React.useState(false);
  const [pageErrorCode, setPageErrorCode] = React.useState(null);
  const [campaign, setCampaign] = React.useState({});
  const [header, setHeader] = React.useState([]);
  const [footer, setFooter] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [list, setList] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");
  const [filters, setFilters] = React.useState({});
  const [selectedCategoryFilter, setSelectedCategoryFilter] =
    React.useState("");
  const [selectedPriceFilter, setSelectedPriceFilter] = React.useState("");
  const [currentItems, setCurrentItems] = React.useState([]);
  const [pageCount, setPageCount] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(9);
  const [itemOffset, setItemOffset] = React.useState(0);

  React.useEffect(() => {
    setSearchValue("");
    handleClearAllFilters();
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    const headerFooterRes = await getHeaderFooter(slug);
    const res = await getProducts(slug);

    if (res.status === 200) {
      setCampaign(headerFooterRes?.data?.campaign);
      setHeader(headerFooterRes?.data?.headers);
      setFooter(headerFooterRes?.data?.footers);
      setData(res?.data?.products);
      setList(res?.data?.products);
      setFilters(res?.data?.filters);
      setIsReady(true);
    } else {
      setHasPageError(true);
      setPageErrorCode(res.status);
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(list?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil((list?.length || 0) / itemsPerPage));
  }, [isReady, data, list, itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % list.length;
    setItemOffset(newOffset);
  };

  const handleCategoryFilterChange = (category) => {
    setSelectedCategoryFilter(category);
  };

  const handlePriceFilterChange = (price) => {
    setSelectedPriceFilter(price);
  };

  const handleClearAllFilters = () => {
    setSelectedCategoryFilter("");
    setSelectedPriceFilter("");
  };

  const handleSearch = () => {
    if (isNotNull(searchValue.trim())) {
      const filteredData =
        data.filter((item) => {
          const fieldsToSearch = [
            item.category,
            item.model,
            item.pn,
            item.series,
            item.short_specification,
            item.title,
          ];

          return fieldsToSearch.some(
            (field) =>
              isNotNull(field) &&
              field.toString().toLowerCase().includes(searchValue.toLowerCase())
          );
        }) || [];
      setList(filteredData);
    } else {
      setList(data);
    }
  };

  React.useEffect(() => {
    handleFilter();
  }, [selectedCategoryFilter, selectedPriceFilter]);

  const handleFilter = async () => {
    setIsLoading(true);
    let apiParams = "?";

    if (selectedCategoryFilter !== "") {
      apiParams += `category=${selectedCategoryFilter}&`;
    }

    if (selectedPriceFilter !== "") {
      apiParams += `price=${selectedPriceFilter}&`;
    }

    // Remove trailing '&' if present
    if (apiParams.endsWith("&")) {
      apiParams = apiParams.slice(0, -1);
    }

    const res = await getProducts(slug, apiParams);

    if (res.status === 200) {
      setData(res?.data?.products);
      setList(res?.data?.products);
    } else {
      setHasPageError(true);
      setPageErrorCode(res.status);
    }
    setIsLoading(false);
  };

  const FilterItem = ({ label, children }) => {
    return (
      <Accordion key={label}>
        <AccordionItem
          toggle={`panel-${label}`}
          className="flex flex-row justify-between items-center gap-3 p-3"
          iconClassName="w-3 h-3 text-gray-900"
          accordionIcon={true}
        >
          <div className="text-base font-semibold leading-tight text-gray-900">
            {label}
          </div>
        </AccordionItem>
        <AccordionPanel
          className="flex flex-col items-start"
          id={`panel-${label}`}
        >
          {children}
        </AccordionPanel>
      </Accordion>
    );
  };

  const FilterItemButton = ({ label, isActive, onClick }) => {
    return (
      <button
        className={`px-3 pb-3 space-y-3 text-sm font-medium ${
          isActive ? "text-primary-700" : "text-gray-900"
        }`}
        onClick={onClick}
      >
        {label}
      </button>
    );
  };

  return (
    <PageContainer
      isLoading={isLoading}
      isReady={isReady}
      slug={slug}
      campaign={campaign}
      header={header}
      footer={footer}
      hasPageError={hasPageError}
      pageErrorCode={pageErrorCode}
    >
      <SectionContainer containerClassName="pt-8 pb-4 flex flex-col md:flex-row justify-between gap-6">
        <div>
          <h1 className="text-3xl font-bold text-gray-900">Products</h1>
          <p className="text-lg text-gray-600">
            Find participating products eligible for rewards
          </p>
        </div>
        <div>
          <SearchInput
            value={searchValue}
            setValue={setSearchValue}
            handleSearch={handleSearch}
            placeholder="Find the item you like"
          />
        </div>
      </SectionContainer>
      <SectionContainer containerClassName="py-5 flex flex-col md:flex-row gap-5">
        {/* Filter */}
        <div className="w-full md:max-w-64 h-fit pl-2 rounded-md border border-gray-200 bg-white shadow-sm">
          <div className="p-4 flex flex-row justify-between gap-3 border-b border-b-gray-200">
            <p className="text-base font-semibold leading-tight text-gray-900">
              Filters
            </p>
            <button
              className="text-sm text-gray-400 underline border-0"
              onClick={handleClearAllFilters}
            >
              Clear all
            </button>
          </div>
          <div className="p-1 border-b border-b-gray-200">
            <FilterItem label="Category">
              <FilterItemButton
                label="All categories"
                isActive={selectedCategoryFilter === ""}
                onClick={() => handleCategoryFilterChange("")}
              />
              {filters?.categories?.length > 0 &&
                filters.categories.map((item, index) => {
                  return (
                    <FilterItemButton
                      key={index}
                      label={item}
                      isActive={selectedCategoryFilter === item}
                      onClick={() => handleCategoryFilterChange(item)}
                    />
                  );
                })}
            </FilterItem>
          </div>
          <div className="p-1 border-b border-b-gray-200">
            <FilterItem label="Price">
              <FilterItemButton
                label="Any price"
                isActive={selectedPriceFilter === ""}
                onClick={() => handlePriceFilterChange("")}
              />
              {filters?.prices &&
                Object.entries(filters.prices)?.length > 0 &&
                Object.entries(filters.prices).map(([key, value], index) => {
                  return (
                    <FilterItemButton
                      key={index}
                      label={value}
                      isActive={selectedPriceFilter === key}
                      onClick={() => handlePriceFilterChange(key)}
                    />
                  );
                })}
              <div className="px-3 pb-3 space-y-3">
                <div className=""></div>
              </div>
            </FilterItem>
          </div>
        </div>

        {/* Products */}
        <div className="w-full space-y-4">
          {currentItems?.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3 gap-x-3.5 gap-y-6">
              {/* Product Item */}
              {currentItems.map((item, index) => (
                <ProductItem key={index} slug={slug} data={item} />
              ))}
            </div>
          ) : (
            <div className="flex justify-center p-4 text-center">
              No product available
            </div>
          )}

          {/* Pagination */}
          <div className="flex flex-col-reverse md:flex-row flex-1 items-center justify-between gap-6 py-4">
            <div>
              <p className="text-sm text-gray-500">
                Showing{" "}
                <span className="font-medium">
                  {list?.length == 0 ? 0 : itemOffset + 1}-
                  {itemOffset + currentItems.length}
                </span>{" "}
                of <span className="font-medium">{list?.length || 0}</span>
              </p>
            </div>
            <ReactPaginate
              pageCount={pageCount}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              previousLabel={
                <FaChevronLeft className="w-3 h-3 text-gray-500" />
              }
              nextLabel={<FaChevronRight className="w-3 h-3 text-gray-500" />}
              breakLabel="..."
              onPageChange={handlePageClick}
              renderOnZeroPageCount={null}
              containerClassName="pagination isolate inline-flex -space-x-px rounded shadow-sm"
              previousClassName="page-item relative inline-flex items-center rounded-l px-3 py-1.5 text-gray-500 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              nextClassName="page-item relative inline-flex items-center rounded-r px-3 py-1.5 text-gray-500 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              pageClassName="page-item relative inline-flex items-center px-3 py-1.5 text-sm font-medium text-gray-500 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              activeClassName="active z-10 bg-primary-100 text-primary-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              breakLinkClassName="page-link page-item relative inline-flex items-center px-3 py-1.5 text-sm font-medium text-gray-500 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              breakClassName="page-item"
            />
          </div>
        </div>
      </SectionContainer>
    </PageContainer>
  );
}

const ProductItem = ({ slug, data }) => {
  return (
    <Link
      to={`/${slug}/products/${data.id}`}
      className="space-y-4 p-4 rounded-lg border border-gray-200 bg-white shadow-sm"
    >
      <div className="">
        <img
          className="w-full aspect-video object-contain object-center mx-auto rounded bg-gray-50"
          src={data?.image}
          alt={data?.model}
        />
      </div>
      <div className="space-y-1">
        <p className="text-xs text-gray-500">
          {data?.category} - {data?.series}
        </p>
        <h3 className="text-base font-semibold text-gray-900">{data?.title}</h3>
        {data?.short_specification ? (
          <p
            className="html-content text-xs text-gray-500"
            dangerouslySetInnerHTML={{ __html: data.short_specification }}
          />
        ) : null}
        <p className="text-xl font-bold leading-tight text-gray-900">
          RM{data?.price}
        </p>
      </div>
    </Link>
  );
};
