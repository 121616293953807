// Every page title
const PageTitle = ({
  title = "",
  subtitle = "",
  containerClassName = "",
  titleClassName = "",
  subtitleClassName = "",
}) => {
  if (title || subtitle) 
    return (
      <>
        <SectionTitle
          title={title}
          subtitle={subtitle}
          containerClassName={`sm:space-y-6 ${containerClassName}`}
          titleClassName={titleClassName}
          subtitleClassName={subtitleClassName}
        />
        <hr className="max-w-60 sm:max-w-md mx-auto my-4 sm:my-6" />
      </>
    );
};

// Home page section title
const SectionTitle = ({
  title,
  subtitle,
  containerClassName,
  titleClassName,
  subtitleClassName,
}) => {
  if (title || subtitle)
    return (
      <div className={`space-y-4 ${containerClassName}`}>
        {title ? (
          <h1
            className={`text-3xl sm:text-5xl leading-none font-extrabold text-gray-800 text-center ${titleClassName}`}
          >
            {title}
          </h1>
        ) : null}
        {subtitle ? (
          <p
            className={`html-content text-base sm:text-xl font-normal text-gray-700 text-center ${subtitleClassName}`}
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        ) : null}
      </div>
    );
};

export { PageTitle, SectionTitle };
