import React from "react";
import { ErrorMessage, useField } from "formik";
import { format, isAfter, isBefore, parseISO } from "date-fns";
import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Date.css";
import { FaCalendarDays } from "react-icons/fa6";
import { Button } from "../../Button/Button";
import { isNotNull } from "../../../Utils/Helpers";

export const Date = ({
  className,
  label,
  name,
  placeholder,
  required,
  minDate,
  maxDate,
}) => {
  const [field, meta, helpers] = useField({ name });

  // Format the date to "yyyy-MM-dd" for storing in Formik state
  const formatDateForFormik = (date) => {
    return date ? format(date, "yyyy-MM-dd") : "";
  };

  // Parse the date from "yyyy-MM-dd" format when setting the value
  const parseDateFromFormik = (value) => {
    if (!value) return null;
    const _date = parseISO(value);
    return _date;
  };

  const handleChange = (date) => {
    helpers.setValue(formatDateForFormik(date));
  };

  const handleTodayClick = () => {
    helpers.setValue(formatDateForFormik(new window.Date()));
  };

  const handleClearClick = () => {
    helpers.setValue(null);
  };

  const isDateInRange = (minDate, maxDate) => {
    if (isNotNull(minDate) && isNotNull(maxDate)) {
      const min = parseISO(minDate); // Parse the date from ISO format
      const max = parseISO(maxDate); // Parse the date from ISO format
      const currentDate = new window.Date();
      return (
        isAfter(currentDate, min) ||
        (currentDate.getTime() === min.getTime() &&
          isBefore(currentDate, max)) ||
        currentDate.getTime() === max.getTime()
      );
    }
    return true;
  };

  const CustomCalendarContainer = ({ className, children }) => {
    return (
      <div className={className}>
        <CalendarContainer className="calendar-container">
          <div className="react-datepicker__tab-loop">{children}</div>
          <div className="calendar-buttons">
            <Button
              primary
              className="today-button"
              label="Today"
              onClick={handleTodayClick}
              disabled={!isDateInRange(minDate, maxDate)}
            />
            <Button
              secondary
              className="clear-button"
              label="Clear"
              onClick={handleClearClick}
            />
          </div>
        </CalendarContainer>
      </div>
    );
  };

  return (
    <div className={`${className} flex flex-col gap-2`}>
      {label ? (
        <label htmlFor={name} className="text-gray-900 text-sm font-medium">
          {label}
          {required && <span className="text-red-500">*</span>}
        </label>
      ) : null}
      <DatePicker
        id={name}
        name={name}
        selected={parseDateFromFormik(meta.value)}
        onChange={handleChange}
        placeholderText={placeholder}
        className={`w-full px-3 py-2 rounded-lg border text-gray-800 focus:outline-none focus:border-blue-500 ${
          meta.touched && meta.error
            ? "border-red-600 bg-red-50"
            : "border-gray-300 bg-gray-50"
        }`}
        showIcon
        icon={
          <FaCalendarDays className="w-3.5 h-3.5 text-gray-500 !px-4 !py-2" />
        }
        dateFormat="dd MMMM yyyy" // Display format in the input field
        autoComplete="off"
        onKeyDown={(e) => e.preventDefault()}
        onFocus={(e) => e.target.blur()}
        calendarContainer={CustomCalendarContainer}
        minDate={minDate ? new window.Date(minDate) : null}
        maxDate={maxDate ? new window.Date(maxDate) : null}
      />

      <ErrorMessage
        name={name}
        component="div"
        className="text-red-500 text-sm mt-1"
      />
    </div>
  );
};
