import React from "react";
import { useParams } from "react-router-dom";
import { PageContainer } from "../Components/Layout/PageContainer";
import { SectionContainer } from "../Components/Layout/SectionContainer";
import { PageTitle } from "../Components/Layout/Layout";
import { FaClock, FaEnvelope, FaPhone } from "react-icons/fa6";
import {
  Accordion,
  AccordionItem,
  AccordionPanel,
} from "../Components/Accordion/Accordion";
import { getContactUs, getHeaderFooter } from "../Utils/Services";
import { renderTextAreaContent } from "../Utils/Helpers";

export default function Contact() {
  const { slug } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isReady, setIsReady] = React.useState(false);
  const [hasPageError, setHasPageError] = React.useState(false);
  const [pageErrorCode, setPageErrorCode] = React.useState(null);
  const [campaign, setCampaign] = React.useState({});
  const [header, setHeader] = React.useState([]);
  const [footer, setFooter] = React.useState([]);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    const headerFooterRes = await getHeaderFooter(slug);
    const res = await getContactUs(slug);

    if (res.status === 200) {
      setCampaign(headerFooterRes?.data?.campaign);
      setHeader(headerFooterRes?.data?.headers);
      setFooter(headerFooterRes?.data?.footers);
      setData(res?.data);
      setIsReady(true);
    } else {
      setHasPageError(true);
      setPageErrorCode(res.status);
    }
    setIsLoading(false);
  };

  return (
    <PageContainer
      isLoading={isLoading}
      isReady={isReady}
      slug={slug}
      campaign={campaign}
      header={header}
      footer={footer}
      hasPageError={hasPageError}
      pageErrorCode={pageErrorCode}
    >
      <SectionContainer containerClassName="py-8 sm:py-12">
        <PageTitle
          title="Contact Us"
          subtitle="Reach out to us if you have any questions"
        />
        <div className="flex flex-col sm:flex-row justify-evenly gap-8 pt-5">
          {data?.contact_details?.whatsapp_display == "yes" && (
            <ContactItem
              icon={<FaPhone className={styles.icon} />}
              title="WhatsApp"
              // description={
              //   <a href={`https://api.whatsapp.com/send/?phone=60196888401&text&type=phone_number`}>
              //     019-6888 401
              //   </a>
              // }
              description={data?.contact_details?.whatsapp_number}
            />
          )}
          {data?.contact_details?.email_display == "yes" && (
            <ContactItem
              icon={<FaEnvelope className={styles.icon} />}
              title="Email"
              // description={
              //   <a href="mailto:a-redemption@perfecdot.com">
              //     a-redemption@perfecdot.com
              //   </a>
              // }
              description={data?.contact_details?.email}
            />
          )}
          {data?.contact_details?.operating_hours_display == "yes" && (
            <ContactItem
              icon={<FaClock className={styles.icon} />}
              title="Operating Hours"
              description={renderTextAreaContent(
                data?.contact_details?.operating_hours
              )}
            />
          )}
        </div>
      </SectionContainer>
      {data?.faqs?.length > 0 && (
        <SectionContainer
          sectionContainerClassName="bg-gray-50"
          containerClassName="py-8 sm:py-12 flex flex-col items-center gap-12"
        >
          <h2 className="text-2xl sm:text-3xl font-bold leading-tight text-gray-900 text-center">
            Frequently asked questions
          </h2>
          <div className="w-full max-w-2xl mx-auto">
            {data.faqs.map((item, index) => (
              <FaqItem
                key={index}
                index={index}
                question={item.question}
                answer={item.answer}
              />
            ))}
          </div>
        </SectionContainer>
      )}
    </PageContainer>
  );
}

const styles = {
  icon: `w-9 h-9 text-primary-800`,
};

const ContactItem = ({ icon, title, description }) => {
  return (
    <div className="flex-1 flex flex-col items-center gap-4">
      <div className="w-16 h-16 flex justify-center items-center rounded-full bg-primary-50">
        {icon}
      </div>
      <div className="space-y-1">
        <p className="text-xl font-bold text-gray-900 text-center">{title}</p>
        <p className="text-base text-gray-500 text-center">{description}</p>
      </div>
    </div>
  );
};

const FaqItem = ({ index, question, answer }) => {
  return (
    <Accordion
      key={index}
      className="[&:not(:last-child)]:border-b [&:not(:last-child)]:border-b-gray-200"
    >
      <AccordionItem
        toggle={`panel-${index}`}
        className="flex flex-row justify-between items-center py-5"
        iconClassName="w-3 h-3 text-gray-900"
        accordionIcon={true}
      >
        <div className="text-lg leading-none font-medium text-gray-900">
          {question}
        </div>
      </AccordionItem>
      <AccordionPanel id={`panel-${index}`}>
        <div
          className="text-base text-gray-500 py-5 border-t border-t-gray-200"
          // dangerouslySetInnerHTML={{ __html: answer }}
        >
          {renderTextAreaContent(answer)}
        </div>
      </AccordionPanel>
    </Accordion>
  );
};
