import React from "react";
import { FaPlus, FaMinus, FaChevronUp, FaChevronDown } from "react-icons/fa";

const Context = React.createContext({});

//custom hook to consume all accordion values
const useAccordion = () => React.useContext(Context);

function Accordion({ children, className }) {
  const [selected, setSelected] = React.useState();

  const toggleItem = React.useCallback(
    (id) => () => {
      setSelected((prevState) => (prevState !== id ? id : ""));
    },
    []
  );
  return (
    <Context.Provider value={{ selected, toggleItem }}>
      <div className={className}>{children}</div>
    </Context.Provider>
  );
}

function AccordionItem({
  toggle,
  children,
  className,
  iconClassName,
  accordionIcon,
}) {
  const { selected, toggleItem } = useAccordion();
  return (
    <div
      role="button"
      onClick={toggleItem(toggle)}
      className={`${className} ${selected === toggle ? "" : ""}`}
    >
      {children}
      {accordionIcon ? (
        selected === toggle ? (
          <FaChevronUp className={iconClassName} />
        ) : (
          <FaChevronDown className={iconClassName} />
        )
      ) : selected === toggle ? (
        <FaMinus className={iconClassName} />
      ) : (
        <FaPlus className={iconClassName} />
      )}
    </div>
  );
}

function AccordionPanel({ children, id, className }) {
  const { selected } = useAccordion();
  const ref = React.useRef();
  const inlineStyle =
    selected === id ? { height: ref.current?.scrollHeight } : { height: 0 };

  return (
    <div
      ref={ref}
      id={id}
      className={`${style.panel} ${className}`}
      style={inlineStyle}
    >
      {children}
    </div>
  );
}

const style = {
  // item: `flex flex-row justify-between items-center`,
  panel: `overflow-hidden md:overflow-x-hidden transition-height ease duration-300`,
};

export { Accordion, AccordionItem, AccordionPanel };
