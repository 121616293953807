export const ErrorPage = ({ code }) => {
  const renderTitle = () => {
    switch (code) {
      case 404:
        return "Something's missing";
      default:
        return "Error";
    }
  };

  const renderDescription = () => {
    switch (code) {
      case 404:
        return "Sorry, we can't find that page.";
      default:
        return "";
    }
  };

  return (
    <section className="bg-white w-full h-screen flex justify-center items-center">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600">
            {code}
          </h1>
          <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl">
            {/* Something's missing. */}
            {renderTitle()}
          </p>
          <p className="mb-4 text-lg font-light text-gray-500">
            {/* Sorry, we can't find that page. */}
            {renderDescription()}
          </p>
          {/* <a
            href=""
            className="inline-flex text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4"
          >
            Back to Homepage
          </a> */}
        </div>
      </div>
    </section>
  );
};
