import React from "react";

export const SectionContainer = ({
  cRef,
  sectionContainerClassName = "",
  containerClassName = "",
  children,
}) => {
  return (
    <div
      ref={cRef}
      className={`relative px-4 ${sectionContainerClassName}`}
      style={{
        display: "relative",
      }}
    >
      <div className={`w-full max-w-screen-xl mx-auto ${containerClassName}`}>
        {children}
      </div>
    </div>
  );
};
