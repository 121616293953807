import React from "react";
import { useParams } from "react-router-dom";
import { PageContainer } from "../Components/Layout/PageContainer";
import { SectionContainer } from "../Components/Layout/SectionContainer";
import { PageTitle } from "../Components/Layout/Layout";
import { getHeaderFooter } from "../Utils/Services";

export default function Terms() {
  const { slug } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isReady, setIsReady] = React.useState(false);
  const [hasPageError, setHasPageError] = React.useState(false);
  const [pageErrorCode, setPageErrorCode] = React.useState(null);
  const [campaign, setCampaign] = React.useState({});
  const [header, setHeader] = React.useState([]);
  const [footer, setFooter] = React.useState([]);
  const [data, setData] = React.useState("");

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    const headerFooterRes = await getHeaderFooter(slug);

    if (headerFooterRes.status === 200) {
      setCampaign(headerFooterRes?.data?.campaign);
      setHeader(headerFooterRes?.data?.headers);
      setFooter(headerFooterRes?.data?.footers);

      const current = headerFooterRes?.data?.footers?.find(
        (item) => item.type === "text" && item.path === `/terms`
      );
      setData(current.description);
      setIsReady(true);
    } else {
      setHasPageError(true);
      setPageErrorCode(headerFooterRes.status);
    }
    setIsLoading(false);
  };

  return (
    <PageContainer
      isLoading={isLoading}
      isReady={isReady}
      slug={slug}
      campaign={campaign}
      header={header}
      footer={footer}
      hasPageError={hasPageError}
      pageErrorCode={pageErrorCode}
    >
      <SectionContainer containerClassName="py-8 sm:py-12">
        <PageTitle title="Terms & Conditions" />
        <div className="pt-2">
          <div
            className="html-content text-base"
            dangerouslySetInnerHTML={{ __html: data }}
          />
        </div>
      </SectionContainer>
    </PageContainer>
  );
}
