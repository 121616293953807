import { SectionContainer } from "../Layout/SectionContainer";
import { getPath } from "../../Utils/Helpers";
import { Button } from "../Button/Button";
import { FaBars } from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";
import { LinkButton } from "../Button/LinkButton";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "../Dropdown/Dropdown";

export const Header = ({ cRef, slug, data }) => {
  const location = useLocation();

  const menuList = [
    {
      path: getPath(slug),
      title: "Home",
    },
  ];

  if (data?.length > 0) {
    data.map((item) => {
      switch (item) {
        case "Products":
          return menuList.push({
            path: getPath(slug, "/products"),
            title: "Products",
          });
        case "Buyer's Guide":
          return menuList.push({
            path: getPath(slug, "/guide"),
            title: "Buyer's Guide",
          });
        case "Track & Trace":
          return menuList.push({
            path: getPath(slug, "/track"),
            title: "Track & Trace",
          });
        case "Stores":
          return menuList.push({
            path: getPath(slug, "/stores"),
            title: "Stores",
          });
        case "Contact Us":
          return menuList.push({
            path: getPath(slug, "/contact"),
            title: "Contact Us",
          });
      }
      // if (item === "Products")
      //   menuList.push({
      //     path: getPath("/products"),
      //     title: "Products",
      //   });
      // if (item === "Buyer's Guide")
      //   menuList.push({
      //     path: getPath("/guide"),
      //     title: "Buyer's Guide",
      //   });
      // if (item === "Track & Trace")
      //   menuList.push({
      //     path: getPath("/track"),
      //     title: "Track & Trace",
      //   });
      // if (item === "Stores")
      //   menuList.push({
      //     path: getPath("/stores"),
      //     title: "Stores",
      //   });
      // if (item === "Contact Us")
      //   menuList.push({
      //     path: getPath("/contact"),
      //     title: "Contact Us",
      //   });
    });
  }

  let currentPath = location.pathname;
  if (currentPath.endsWith("/")) {
    currentPath = currentPath.slice(0, -1);
  }

  return (
    <SectionContainer
      cRef={cRef}
      sectionContainerClassName="border-b border-b-gray-200 lg:border-0"
      containerClassName="py-6 flex flex-row gap-8 justify-between items-center"
    >
      <div className="w-full flex flex-row justify-between lg:justify-start gap-8">
        <Link to={getPath(slug)}>
          <img
            src="/images/logo-acer_green.svg"
            className="w-auto max-h-[50px]"
            alt=""
          />
        </Link>

        {/* Mobile Menu */}
        <Dropdown
          className="flex justify-center items-center lg:hidden"
          buttonClassName=""
        >
          <DropdownToggle>
            <FaBars className="text-xl text-gray-500" />
          </DropdownToggle>
          {/* Header Height: Adjust top value here */}
          <DropdownMenu className="absolute top-[76px] left-0 bg-white border-t- border-b-grey-200 w-full max-w-screen-sm p-6 space-y-6 transition-all shadow-md z-30 max-h-[calc(100vh-76px)] overflow-auto">
            {menuList?.map((item, index) => {
              const isActive =
                currentPath.split("/")[2] === item.path.split("/")[2];

              return (
                <DropdownItem key={index}>
                  <Link
                    key={index}
                    className={`text-base font-medium ${
                      isActive ? "text-primary-700" : "text-gray-900"
                    } transition ease-in-out duration-150 hover:text-primary-700`}
                    to={item.path}
                  >
                    {item.title}
                  </Link>
                </DropdownItem>
              );
            })}
            <DropdownItem>
              <LinkButton
                primary
                className="flex"
                label="Redeem"
                link={getPath(slug, "/participate")}
              />
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>

        {/* Desktop Menu */}
        <div className="hidden lg:flex flex flex-row items-center gap-8">
          {menuList?.map((item, index) => {
            const isActive =
              currentPath.split("/")[2] === item.path.split("/")[2];

            return (
              <Link
                key={index}
                className={`text-base font-medium ${
                  isActive ? "text-primary-700" : "text-gray-900"
                } transition ease-in-out duration-150 hover:text-primary-700`}
                to={item.path}
              >
                {item.title}
              </Link>
            );
          })}
        </div>
      </div>
      <LinkButton
        primary
        className="hidden lg:flex"
        label="Redeem"
        link={getPath(slug, "/participate")}
      />
    </SectionContainer>
  );
};
