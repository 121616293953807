import React from "react";
import { ErrorMessage, useField } from "formik";
import { FaFileImage, FaFilePdf, FaTrashCan } from "react-icons/fa6";

export const FileUpload = ({
  className,
  label,
  name,
  placeholder,
  required,
}) => {
  const [field, meta, helpers] = useField({ name });

  const handleChange = (event) => {
    const file = event.currentTarget.files[0];
    helpers.setValue(file);
  };

  return (
    <div className={`${className} flex flex-col gap-2`}>
      <label htmlFor={name} className="text-gray-900 text-sm font-medium">
        {label}
        {required && <span className="text-red-500">*</span>}
      </label>
      {field.value ? (
        <div className="flex py-2 px-3 rounded-lg border text-gray-800 bg-gray-50">
          <div className="flex items-center mr-3">
            {field.value?.type.includes("image") ? (
              <FaFileImage className="w-5 h-5 text-gray-500" />
            ) : (
              <FaFilePdf className="w-5 h-5 text-gray-500" />
            )}
          </div>
          <div className="flex flex-col flex-[1_0_0]">
            <p className="text-gray-900 text-sm font-medium">
              {field.value?.name}
            </p>
            <p className="text-xs text-gray-500">
              {(field.value?.size / 1024).toFixed(2)} KB
            </p>
          </div>

          <button
            className="flex items-center"
            onClick={() => helpers.setValue("")}
          >
            <FaTrashCan className="w-5 h-5 text-gray-500" />
          </button>
        </div>
      ) : (
        <input
          id={name}
          name={name}
          type="file"
          accept="image/png, image/jpeg, .pdf"
          onChange={handleChange}
          onBlur={() => helpers.setTouched(true)}
          className={`rounded-lg border text-gray-800 ${
            meta.touched && meta.error
              ? "border-red-600 bg-red-50"
              : "border-gray-300 bg-gray-50"
          }`}
        />
      )}

      {placeholder ? (
        <p className="text-gray-500 text-xs font-normal">{placeholder}</p>
      ) : null}

      <ErrorMessage
        name={name}
        component="div"
        className="text-red-500 text-sm mt-1"
      />
    </div>
  );
};
