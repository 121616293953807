import React from "react";
import { Link, useParams } from "react-router-dom";
import { PageContainer } from "../Components/Layout/PageContainer";
import { SectionContainer } from "../Components/Layout/SectionContainer";
import { PageTitle } from "../Components/Layout/Layout";
import { getBuyerGuides, getHeaderFooter } from "../Utils/Services";

export default function Guide() {
  const { slug } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isReady, setIsReady] = React.useState(false);
  const [hasPageError, setHasPageError] = React.useState(false);
  const [pageErrorCode, setPageErrorCode] = React.useState(null);
  const [campaign, setCampaign] = React.useState({});
  const [header, setHeader] = React.useState([]);
  const [footer, setFooter] = React.useState([]);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    const headerFooterRes = await getHeaderFooter(slug);
    const res = await getBuyerGuides(slug);

    if (res.status === 200) {
      setCampaign(headerFooterRes?.data?.campaign);
      setHeader(headerFooterRes?.data?.headers);
      setFooter(headerFooterRes?.data?.footers);
      setData(res?.data);
      setIsReady(true);
    } else {
      setHasPageError(true);
      setPageErrorCode(res.status);
    }
    setIsLoading(false);
  };

  return (
    <PageContainer
      isLoading={isLoading}
      isReady={isReady}
      slug={slug}
      campaign={campaign}
      header={header}
      footer={footer}
      hasPageError={hasPageError}
      pageErrorCode={pageErrorCode}
    >
      <SectionContainer containerClassName="py-8 sm:py-12">
        <PageTitle
          title="Buyer's guide"
          subtitle="Find the products that best serve your technology needs."
        />

        <div className="flex flex-col items-center space-y-5 sm:space-y-12">
          <p className="text-base text-gray-500 text-center">
            Product summary for each of the Acer models including our Predator
            and ConceptD lineups.
            <br />
            Our guides are updated quarterly to provide you with the latest
            information.
          </p>
          <div className="grid grid-cols-3 gap-2.5 sm:gap-5">
            {data?.length > 0 &&
              data.map((item, index) => {
                return (
                  <div key={index} className="max-w-xs max-h-80">
                    <Link to={item.file} target="_blank">
                      <img
                        className="w-full h-full"
                        src={item.thumbnail}
                        alt={item.name}
                      />
                    </Link>
                  </div>
                );
              })}
          </div>
        </div>
      </SectionContainer>
    </PageContainer>
  );
}
