import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { MdOutlineClose } from "react-icons/md";

export const Modal = ({ open, setOpen, showCloseButton, children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setIsModalOpen(open);
  }, [open]);

  const toggle = () => {
    setOpen(!isModalOpen);
    setIsModalOpen(!isModalOpen);
  };

  return (
    <Dialog
      // onClose={toggle}
      aria-labelledby="customized-dialog-title"
      open={isModalOpen}
    >
      <div className="p-2">
        {showCloseButton ? (
          <div className="flex flex-row justify-end">
            <button type="button" className="-mr-2 -my-2 p-4 " onClick={toggle}>
              <MdOutlineClose className="text-base text-gray-500 hover:text-black" />
            </button>
          </div>
        ) : null}
        <div className="flex flex-col justify-center items-center gap-4 p-2 sm:p-8">
          {children}
        </div>
      </div>
    </Dialog>
  );
};
