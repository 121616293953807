export const API_BASE_URL = process.env.REACT_APP_API;
export const GOOGLE_MAPS_API_KEY = "AIzaSyBJd3FDqo-vwrB-6mVyELycweWvggWCUOM";
export const GOOGLE_MAPS_ID = "f464c4e8a6f7fd74";
export const CAMPAIGN_SLUG = "builtforglory";
export const validNricCodes = [
  "01",
  "21",
  "22",
  "23",
  "24",
  "02",
  "25",
  "26",
  "27",
  "03",
  "28",
  "29",
  "04",
  "30",
  "05",
  "31",
  "59",
  "06",
  "32",
  "33",
  "07",
  "34",
  "35",
  "08",
  "36",
  "37",
  "38",
  "39",
  "09",
  "40",
  "10",
  "41",
  "42",
  "43",
  "44",
  "11",
  "45",
  "46",
  "12",
  "47",
  "48",
  "49",
  "13",
  "50",
  "51",
  "52",
  "53",
  "14",
  "54",
  "55",
  "56",
  "57",
  "15",
  "58",
  "16",
  "60",
  "61",
  "62",
  "63",
  "64",
  "65",
  "66",
  "67",
  "68",
  // "69",
  // "70",
  "71",
  "72",
  // "73",
  "74",
  "75",
  "76",
  "77",
  "78",
  "79",
  // "80",
  // "81",
  "82",
  "83",
  "84",
  "85",
  "86",
  "87",
  "88",
  "89",
  "90",
  "91",
  "92",
  "93",
  // "94",
  // "95",
  // "96",
  // "97",
  "98",
  "99",
];
