import { FaX } from "react-icons/fa6";

export const ErrorMessageBox = ({ className, message }) => {
  return (
    <div
      className={`w-fit min-h-12 mx-auto flex flex-row items-center gap-2.5 py-2 px-4 rounded-lg border border-red-500 bg-red-50 mb-5 ${className}`}
    >
      <FaX className="text-base text-red-700" />
      <p className="text-sm text-red-700">{message}</p>
    </div>
  );
};
