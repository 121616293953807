import { Link } from "react-router-dom";
import { getPath } from "../../Utils/Helpers";
import { SectionContainer } from "../Layout/SectionContainer";

export const Footer = ({ cRef, slug, data }) => {
  return (
    <SectionContainer
      cRef={cRef}
      sectionContainerClassName="border-t border-t-gray-100"
      containerClassName="py-8 sm:py-16 flex flex-col gap-6 justify-center items-center"
    >
      <img src="/images/logo-acer_green.svg" className="" alt="" />
      <p className="text-sm text-gray-500 text-center">
        &copy; 2024 Acer Sales and Services Sdn. Bhd. 199001012840 (204410-A).
      </p>
      <p className="text-sm font-light text-gray-500 text-center mb-2">
        All rights reserved. Intel&copy;, the Intel&copy; logo, and Intel&copy;
        Core are trademarks of Intel&copy; Corporation or its subsidiaries.
      </p>
      <div className="flex flex-row gap-8">
        {data?.length > 0 &&
          data.map((item, index) => {
            return (
              <Link
                key={index}
                className="text-sm font-medium text-gray-900"
                to={
                  item.type === "file"
                    ? item.file
                    : item.type === "text"
                    ? getPath(slug, item.path)
                    : item.description
                }
                target="_blank"
              >
                {item.title}
              </Link>
            );
          })}
        {/* <a
          className="text-sm font-medium text-gray-900"
          href={getPath("/terms")}
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        <a
          className="text-sm font-medium text-gray-900"
          href={getPath("/privacy")}
          target="_blank"
          rel="noreferrer"
        >
          Terms & Conditions
        </a> */}
      </div>
    </SectionContainer>
  );
};
