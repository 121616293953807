import React from "react";

function useToggle() {
  const [show, setShow] = React.useState(false);
  const ref = React.useRef(null);

  const toggle = React.useCallback(() => {
    setShow((prevState) => !prevState);
  }, []);

  return {
    show,
    toggle,
    ref,
  };
}

function useOutsideClick(ref, callback) {
  React.useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref, callback]);
}

function Dropdown({ children, className, buttonClassName }) {
  const { ref, show, toggle } = useToggle();

  /* First child contains the dropdown toggle */
  const dropdownToggle = children[0];

  /* Second child contains the dropdown menu */
  const dropdownMenu = children[1];

  useOutsideClick(ref, () => {
    if (show) {
      toggle();
    }
  });

  return (
    <div className={className}>
      <button
        ref={ref}
        className={buttonClassName}
        onClick={toggle}
        type="button"
        id="options-menu"
        aria-expanded="true"
        aria-haspopup="true"
      >
        {dropdownToggle}
      </button>
      {show && <>{dropdownMenu}</>}
    </div>
  );
}

function DropdownToggle({ children }) {
  return <>{children}</>;
}

function DropdownMenu({ children, className }) {
  const ref = React.useRef(null);
  const { show, toggle } = useToggle();

  useOutsideClick(ref, () => {
    if (show) {
      toggle();
    }
  });

  return (
    <div ref={ref} className="">
      <div
        // style={{ transform: "translate3d(0px, 3px, 0px)" }}
        // className={style.menu}
        className={className}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
      >
        {children}
      </div>
    </div>
  );
}

/* You can wrap the a tag with Link and pass href prop to Link if you are using either Create-React-App, Next.js or Gatsby */
function DropdownItem({ children, stopPropagation }) {
  const handleStopPropagation = (event) => {
    event.stopPropagation();
  };

  return (
    <div
      tabIndex={0}
      role="menuitem"
      onClick={stopPropagation && handleStopPropagation}
    >
      {children}
    </div>
  );
}

const style = {
  item: `block w-full py-1 px-8 mb-2 text-sm font-normal clear-both whitespace-nowrap border-0 hover:bg-gray-200 cursor-pointer`,
  menu: `block z-30 absolute top-0 left-0 bg-white float-left py-2 px-0 text-left border border-gray-300 rounded-sm mt-0.5 mb-0 mx-0 bg-clip-padding`,
};

export { Dropdown, DropdownToggle, DropdownMenu, DropdownItem };
