import React from "react";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import Loader from "../Loader/Loader";
import { ErrorPage } from "../../Pages/ErrorPage";
import { Helmet } from "react-helmet";

export const PageContainer = ({
  isLoading,
  isReady,
  slug,
  campaign,
  header,
  footer,
  hasPageError,
  pageErrorCode,
  children,
}) => {
  const headerRef = React.useRef(null);
  const footerRef = React.useRef(null);
  const [headerHeight, setHeaderHeight] = React.useState(0);
  const [footerHeight, setFooterHeight] = React.useState(0);

  React.useEffect(() => {
    if (headerRef.current && footerRef) {
      setHeaderHeight(headerRef.current.clientHeight);
      setFooterHeight(footerRef.current.clientHeight);
    }
  }, []);

  return (
    <div>
      <Helmet>
        <title>{campaign?.name || ""}</title>
        {/* <meta name="description" content={campaign?.name || ""} /> */}
      </Helmet>
      {isReady && (
        <>
          <Header cRef={headerRef} slug={slug} data={header} />
          <div
            className="w-full flex flex-col"
            // style={{
            //   minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`,
            // }}
          >
            {children}
          </div>
          <Footer cRef={footerRef} slug={slug} data={footer} />
        </>
      )}
      {hasPageError && <ErrorPage code={pageErrorCode} />}
      <Loader className={isLoading ? "flex" : "hidden"} />
    </div>
  );
};
