import React from "react";
import { useParams } from "react-router-dom";
import { PageContainer } from "../Components/Layout/PageContainer";
import { SectionContainer } from "../Components/Layout/SectionContainer";
import { SectionTitle } from "../Components/Layout/Layout";
import Slider from "react-slick";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { getHeaderFooter, getHome } from "../Utils/Services";
import { getPath, isNotNull } from "../Utils/Helpers";
import { LinkButton } from "../Components/Button/LinkButton";

export default function Home() {
  const { slug } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isReady, setIsReady] = React.useState(false);
  const [hasPageError, setHasPageError] = React.useState(false);
  const [pageErrorCode, setPageErrorCode] = React.useState(null);
  const [campaign, setCampaign] = React.useState({});
  const [header, setHeader] = React.useState([]);
  const [footer, setFooter] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [isCustomCss, setIsCustomCss] = React.useState(false);

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    const headerFooterRes = await getHeaderFooter(slug);
    const res = await getHome(slug);

    if (res.status === 200) {
      setIsCustomCss(slug === "builtforglory");
      setCampaign(headerFooterRes?.data?.campaign);
      setHeader(headerFooterRes?.data?.headers);
      setFooter(headerFooterRes?.data?.footers);
      setData(res?.data);
      setIsReady(true);
    } else {
      setHasPageError(true);
      setPageErrorCode(res.status);
    }
    setIsLoading(false);
  };

  return (
    <PageContainer
      isLoading={isLoading}
      isReady={isReady}
      slug={slug}
      campaign={campaign}
      header={header}
      footer={footer}
      hasPageError={hasPageError}
      pageErrorCode={pageErrorCode}
    >
      <HeroSlider data={data?.hero_sliders} />

      <LeftRightLayout
        data={data?.campaign_details}
        sectionContainerClassName={
          isCustomCss ? "bg-[#0E2545] pb-10 !px-0" : ""
        }
        containerClassName={isCustomCss ? "" : "py-10 sm:flex-row gap-8"}
        isCustomCss={isCustomCss}
        buttonContainerClassName={isCustomCss ? "justify-center" : ""}
        buttonPrimaryClassName={isCustomCss ? "!bg-[#E39E41] !text-white" : ""}
        buttonSecondaryClassName={
          isCustomCss ? "!bg-[#385DA3] !text-white" : ""
        }
        // sectionContainerClassName="!p-0"
        // containerClassName="gap-0"
        // contentClassName="py-4 px-16"
      />

      {data?.redemptionSteps?.length > 0 && (
        <TopBottomLayout
          sectionContainerClassName={isCustomCss ? "bg-[#375385]" : ""}
          textClassName={isCustomCss ? "!text-white" : ""}
          title="How to Redeem"
          subtitle="Redeem exciting gifts when you participate in our campaign"
        >
          <RedemptionSteps
            slug={slug}
            data={data.redemptionSteps}
            textClassName={isCustomCss ? "!text-white" : ""}
            stepClassName={isCustomCss ? "!bg-[#385DA3]" : ""}
            buttonClassName={isCustomCss ? "!bg-[#B62D89] !text-white" : ""}
          />
        </TopBottomLayout>
      )}

      <TopBottomLayout
        sectionContainerClassName={
          isCustomCss ? "bg-[#000116] pb-10 !px-0" : ""
        }
        containerClassName={isCustomCss ? "!py-0 !max-w-none" : "!space-y-4"}
        title={data?.rewards?.section_title}
        subtitle={data?.rewards?.section_description}
      >
        <Rewards
          slug={slug}
          data={data?.rewards?.rewards}
          buttonClassName={isCustomCss ? "!bg-[#72C3EE] !text-[#000116]" : ""}
        />
      </TopBottomLayout>

      {data?.whatsNew?.type === "image-only" ? (
        <Hero
          data={data.whatsNew}
          className={`${isCustomCss ? "aspect-auto" : ""} !object-contain`}
        />
      ) : data?.whatsNew?.type === "image-text" ? (
        <LeftRightLayout
          data={data.whatsNew}
          // containerClassName="py-24"
        />
      ) : null}
    </PageContainer>
  );
}

const Hero = ({ data, className }) => {
  return (
    <div className="overflow-hidden">
      {data?.desktop_image && (
        <img
          className={`!hidden sm:!block w-full aspect-video object-cover object-center ${className}`}
          src={data.desktop_image}
          alt={data?.alt_text}
        />
      )}
      {data?.mobile_image && (
        <img
          className={`sm:!hidden w-full aspect-bannerMobile object-cover object-center ${className}`}
          src={data.mobile_image}
          alt={data?.alt_text}
        />
      )}
    </div>
  );
};

const HeroSlider = ({ data }) => {
  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} !right-3 sm:!right-6 !flex !justify-center !items-center !w-8 !h-8 sm:!w-12 sm:!h-12 !rounded-full !bg-[rgba(255,255,255,0.30)]`}
        style={{ ...style }}
        onClick={onClick}
      >
        <FaChevronRight className={`text-base sm:text-xl text-white`} />
      </div>
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} !left-3 sm:!left-6 !flex !justify-center !items-center !w-8 !h-8 sm:!w-12 sm:!h-12 !rounded-full !bg-[rgba(255,255,255,0.30)]`}
        style={{ ...style }}
        onClick={onClick}
      >
        <FaChevronLeft className={`text-base sm:text-xl text-white`} />
      </div>
    );
  }

  const settings = {
    dots: true,
    infinite: data?.length > 1,
    speed: 500,
    autoplaySpeed: 5000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Slider {...settings}>
      {data?.length > 0 &&
        data.map((item, index) => <Hero key={index} data={item} />)}
    </Slider>
  );
};

const RedemptionSteps = ({
  slug,
  data,
  textClassName,
  stepClassName,
  buttonClassName,
}) => {
  return (
    <>
      {data?.length > 0 && (
        <div className="flex flex-row flex-wrap justify-evenly gap-8">
          {data.map((item, index) => {
            return (
              <div
                key={index}
                className="w-full sm:w-1/4 flex flex-col gap-4 items-center"
              >
                <div
                  className={`w-12 h-12 rounded-lg bg-primary-50 py-2 px-3 flex justify-center items-center ${stepClassName} ${textClassName}`}
                >
                  {item.step}
                </div>
                {item.title ? (
                  <p
                    className={`text-xl font-bold text-gray-900 text-center ${textClassName}`}
                  >
                    {item.title}
                  </p>
                ) : null}
                {item.description ? (
                  <p
                    className={`html-content text-base text-gray-500 text-center ${textClassName}`}
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                ) : null}
                {item.button_type !== "no" ? (
                  <LinkButton
                    primary={item.button_type === "primary"}
                    secondary={item.button_type === "secondary"}
                    className={buttonClassName}
                    label={item.button_title}
                    link={item.button_link}
                  />
                ) : null}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

const Rewards = ({ slug, data, buttonClassName }) => {
  return (
    <>
      {data?.length > 0 && (
        <div className="flex flex-row flex-wrap justify-evenly -mx-2">
          {data.map((item, index) => {
            return (
              <div
                key={index}
                className={`w-full ${
                  data?.length > 4 ? "sm:w-1/4" : `sm:w-1/${data?.length}`
                } flex flex-col gap-4 items-center px-2 py-6`}
              >
                <img className="" src={item.image} alt="" />
                {item.description ? (
                  <p
                    className="html-content text-base text-gray-500 text-center"
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                ) : null}
              </div>
            );
          })}
        </div>
      )}
      <div className="flex justify-center items-center">
        <LinkButton
          primary
          className={buttonClassName}
          label="Redeem Now"
          link={getPath(slug, "/participate")}
        />
      </div>
    </>
  );
};

const TopBottomLayout = ({
  children,
  sectionContainerClassName,
  containerClassName,
  title,
  subtitle,
  textClassName,
}) => {
  return (
    <SectionContainer
      sectionContainerClassName={sectionContainerClassName}
      containerClassName={`py-10 space-y-8 ${containerClassName}`}
    >
      <SectionTitle
        title={title}
        subtitle={subtitle}
        titleClassName={textClassName}
        subtitleClassName={textClassName}
      />
      {children}
    </SectionContainer>
  );
};

const LeftRightLayout = ({
  data,
  sectionContainerClassName,
  containerClassName,
  contentClassName,
  isCustomCss,
  buttonContainerClassName,
  buttonPrimaryClassName,
  buttonSecondaryClassName,
}) => {
  if (data)
    return (
      <SectionContainer
        sectionContainerClassName={sectionContainerClassName}
        containerClassName={`flex flex-col justify-center items-center ${containerClassName}`}
        isCustomCss={isCustomCss}
      >
        {(data?.section_image || data?.image) && (
          <div className={`w-full ${isCustomCss ? "" : "sm:w-1/2"}`}>
            <img
              className="mx-auto"
              src={data?.section_image || data?.image}
              alt=""
            />
          </div>
        )}
        <div
          className={`w-full ${
            isCustomCss ? "" : "sm:w-1/2"
          } space-y-8 ${contentClassName}`}
        >
          <SectionTitle
            title={data?.section_title || data?.primary_title}
            subtitle={data?.primary_description || data?.secondary_title}
            titleClassName="!text-4xl !text-left"
            subtitleClassName="!text-left"
          />
          {(data?.secondary_description || data?.description) && (
            <p
              className="html-content text-base"
              dangerouslySetInnerHTML={{
                __html: data?.secondary_description || data?.description,
              }}
            />
          )}
          {(data?.primary_button_text || data?.secondary_button_text) && (
            <div className={`flex flex-row gap-2 ${buttonContainerClassName}`}>
              {isNotNull(data?.primary_button_text) && (
                <LinkButton
                  primary={!buttonPrimaryClassName}
                  className={buttonPrimaryClassName}
                  label={data.primary_button_text}
                  link={data.primary_button_link}
                />
              )}

              {isNotNull(data?.secondary_button_text) && (
                <LinkButton
                  secondary={!buttonSecondaryClassName}
                  className={buttonSecondaryClassName}
                  label={data.secondary_button_text}
                  link={data.secondary_button_link}
                />
              )}
            </div>
          )}
        </div>
      </SectionContainer>
    );
};
