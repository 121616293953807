import axios from "axios";
import { API_BASE_URL } from "./Constants";

const ApiInstance = axios.create({
  baseURL: API_BASE_URL,
});

// Request interceptor for API calls
ApiInstance.interceptors.request.use(
  async (config) => {
    // Determine content type based on the request
    if (config.data instanceof FormData) {
      // If data is FormData, set Content-Type accordingly
      config.headers["Content-Type"] = "multipart/form-data";
    } else {
      // Default to JSON content type
      config.headers["Content-Type"] = "application/json";
    }

    // Always set Accept header to JSON
    config.headers.Accept = "application/json";

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for API calls
ApiInstance.interceptors.response.use(
  (response) => {
    return { data: response.data, status: response.status };
  },
  async function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      const { status, data } = error.response;
      return { data, status };
    } else if (error.request) {
      // The request was made but no response was received
      throw new Error("Server not reachable");
    }
    // Something else happened while setting up the request
    console.error(`API Error: ${error.message}`);
    throw new Error("Error");
  }
);

export default ApiInstance;
