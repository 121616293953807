import { ErrorMessage, Field, useField } from "formik";
import React from "react";

export const RadioButton = ({
  className,
  label,
  name,
  options,
  placeholder,
  required,
  onChange = () => {},
}) => {
  const [field, meta, helpers] = useField({ name });

  return (
    <div className={`${className} flex flex-col gap-2`}>
      {label ? (
        <p className="text-gray-900 text-sm font-medium">
          {label}
          {required && <span className="text-red-500">*</span>}
        </p>
      ) : null}

      <div role="group" className="flex flex-col gap-2">
        {options.map((option) => (
          <label
            key={option.value}
            htmlFor={`${name}-${option.value}`}
            className="text-gray-500 text-sm font-medium"
          >
            <Field
              id={`${name}-${option.value}`}
              name={name}
              type="radio"
              value={option.value}
              className={`mr-4 h-4 w-4 text-primary-600 focus:ring-0 focus:ring-offset-0 ${
                meta.touched && meta.error
                  ? "border-red-600 bg-red-50"
                  : "bg-white border-gray-300"
              }`}
              onChange={() => {
                helpers.setValue(option.value);
              }}
            />
            {option.label}
          </label>
        ))}
      </div>

      {placeholder ? (
        <p class="text-gray-500 text-xs font-normal">{placeholder}</p>
      ) : null}

      <ErrorMessage
        name={name}
        component="div"
        className="text-red-500 text-sm mt-1"
      />
    </div>
  );
};
