import React from "react";
import { ErrorMessage, useField } from "formik";

export const TermCheckbox = ({
  className,
  label,
  name,
  options,
  placeholder,
  required,
}) => {
  const [field, meta, helpers] = useField({ name, type: "checkbox" });

  return (
    <div className={`${className} flex flex-col gap-2`}>
      {label ? (
        <p htmlFor={name} className="text-gray-900 text-sm font-medium">
          {label}
          {required && <span className="text-red-500">*</span>}
        </p>
      ) : null}
      {options.map((option, index) => (
        <label
          key={index}
          htmlFor={`${name}-${option.value}`}
          className="text-gray-500 text-sm font-medium flex items-center"
        >
          <input
            id={`${name}-${option.value}`}
            name={name}
            {...field}
            type="checkbox"
            className={`w-4 h-4 text-primary-600 rounded focus:ring-0 focus:ring-offset-0 mr-4 ${
              // className={`w-4 h-4 text-primary-700 bg-gray-100 border-gray-300 rounded mr-2 focus:ring-0 focus:ring-offset-0 ${
              meta.touched && meta.error
                ? "border-red-600 bg-red-50"
                : "bg-white border-gray-300"
            }`}
          />
          <span>{option.label}</span>
        </label>
      ))}

      {placeholder ? (
        <p class="text-gray-500 text-xs font-normal">{placeholder}</p>
      ) : null}

      <ErrorMessage
        name={name}
        component="div"
        className="text-red-500 text-sm mt-1"
      />
    </div>
  );
};
