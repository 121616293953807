import React from "react";
import { useParams } from "react-router-dom";
import { PageContainer } from "../Components/Layout/PageContainer";
import { SectionContainer } from "../Components/Layout/SectionContainer";
import { PageTitle } from "../Components/Layout/Layout";
import { ErrorMessageBox } from "../Components/Form/ErrorMessageBox/ErrorMessageBox";
import { SearchInput } from "../Components/Form/SearchInput/SearchInput";
import { FaPhone } from "react-icons/fa6";
import { getHeaderFooter, getStores } from "../Utils/Services";
import { AdvancedMarker, APIProvider, Map } from "@vis.gl/react-google-maps";
import { GOOGLE_MAPS_API_KEY, GOOGLE_MAPS_ID } from "../Utils/Constants";
import { isNotNull } from "../Utils/Helpers";
import { fromAddress, setDefaults } from "react-geocode";

export default function Stores() {
  const { slug } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isReady, setIsReady] = React.useState(false);
  const [hasPageError, setHasPageError] = React.useState(false);
  const [pageErrorCode, setPageErrorCode] = React.useState(null);
  const [campaign, setCampaign] = React.useState({});
  const [header, setHeader] = React.useState([]);
  const [footer, setFooter] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [list, setList] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");

  React.useEffect(() => {
    setSearchValue("");
    fetchData();
    // Geocoding
    setDefaults({
      key: GOOGLE_MAPS_API_KEY,
      language: "en",
      region: "my",
    });
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    const headerFooterRes = await getHeaderFooter(slug);
    const res = await getStores(slug);

    if (res.status === 200) {
      setCampaign(headerFooterRes?.data?.campaign);
      setHeader(headerFooterRes?.data?.headers);
      setFooter(headerFooterRes?.data?.footers);
      setData(res?.data);
      setList(res?.data);
      setIsReady(true);
    } else {
      setHasPageError(true);
      setPageErrorCode(res.status);
    }
    setIsLoading(false);
  };

  const handleSearch = () => {
    if (isNotNull(searchValue.trim())) {
      const filteredData =
        data.filter((item) =>
          Object.values(item).some((value) =>
            isNotNull(value)
              ? value
                  .toString()
                  .toLowerCase()
                  .includes(searchValue.toLowerCase())
              : null
          )
        ) || [];
      setList(filteredData);
    } else {
      setList(data);
    }
  };

  return (
    <PageContainer
      isLoading={isLoading}
      isReady={isReady}
      slug={slug}
      campaign={campaign}
      header={header}
      footer={footer}
      hasPageError={hasPageError}
      pageErrorCode={pageErrorCode}
    >
      <SectionContainer containerClassName="py-8 sm:py-12">
        <PageTitle
          title="Stores"
          subtitle="Participating stores for redemption within the campaign period"
        />
        <div className="flex flex-col sm:flex-row gap-5 mt-8">
          <div className="w-full sm:w-1/2 space-y-5">
            <SearchInput
              value={searchValue}
              setValue={setSearchValue}
              handleSearch={handleSearch}
              placeholder="Find your favourite store"
            />
            {list?.length <= 0 && (
              <ErrorMessageBox
                className="sm:hidden w-full"
                message="Sorry, no results found for your search. Please try again."
              />
            )}
            <div className="sm:hidden">
              <StoreMap data={list} />
            </div>
            <div className="space-y-3 sm:max-h-96 sm:overflow-y-scroll">
              {list?.length > 0 ? (
                list.map((item, index) => {
                  return <StoreItem key={index} item={item} />;
                })
              ) : searchValue ? (
                <div>
                  <ErrorMessageBox
                    className="w-full hidden sm:flex"
                    message="Sorry, no results found for your search. Please try again."
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className="hidden sm:block sm:w-1/2">
            <StoreMap data={list} />
          </div>
        </div>
      </SectionContainer>
    </PageContainer>
  );
}

const StoreItem = ({ item }) => {
  return (
    <div className="flex flex-row bg-gray-50 border border-gray-200 rounded-lg overflow-hidden">
      {/* <img
        className="flex-1 max-w-36 object-cover object-center"
        src={}
        alt={item.name}
      /> */}
      <div className="py-4 px-6 space-y-2">
        <p className="text-sm font-bold text-gray-900">
          {item.name} {item.location ? `- ${item.location}` : ""}
        </p>
        <p className="text-sm text-gray-500">
          {item.address} {item.postcode} {item.city} {item.state}
        </p>
        <div className="flex flex-row items-center gap-2">
          <FaPhone className="w-3 h-3 text-gray-500" />
          <p className="text-sm text-gray-500">{item.pic_contact_no}</p>
        </div>
      </div>
    </div>
  );
};

const StoreMap = ({ data }) => (
  <APIProvider apiKey={GOOGLE_MAPS_API_KEY} libraries={["marker", "geocoding"]}>
    <Map
      mapId={GOOGLE_MAPS_ID}
      style={{ maxWidth: "500px", aspectRatio: 1, margin: "auto" }}
      defaultCenter={{ lat: 3.1319, lng: 101.6841 }}
      defaultZoom={13}
      gestureHandling={"greedy"}
      disableDefaultUI={false}
      reuseMaps={true}
    >
      {data?.length > 0 &&
        data.map((item, index) => {
          return <Marker key={index} data={item} />;
        })}
    </Map>
  </APIProvider>
);

const Marker = ({ data }) => {
  const [latitude, setLatitude] = React.useState(null);
  const [longitude, setLongitude] = React.useState(null);

  React.useEffect(() => {
    // fromAddress(`${data.address} ${data.postcode} ${data.city} ${data.state}`)
    //   .then(({ results }) => {
    //     const { lat, lng } = results[0].geometry.location;
    //     // console.log("data: ", data.latitude, data.longitude);
    //     // console.log("geo:  ", lat, lng);
    //     setLatitude(lat);
    //     setLongitude(lng);
    //   })
    //   .catch(console.error);
  }, []);

  if (isNotNull(data.latitude) && isNotNull(data.longitude))
    return (
      <AdvancedMarker
        position={{
          lat: Number(data.latitude),
          lng: Number(data.longitude),
        }}
        clickable={false}
        title={`${data.name} ${data.location ? `- ${data.location}` : ""}`}
      />
    );
};
