import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home";
import Contact from "./Pages/Contact";
import Guide from "./Pages/Guide";
import Participate from "./Pages/Participate";
import Products from "./Pages/Products";
import ProductDetails from "./Pages/ProductDetails";
import Stores from "./Pages/Stores";
import Track from "./Pages/Track";
import Terms from "./Pages/Terms";
import Privacy from "./Pages/Privacy";
import { ErrorPage } from "./Pages/ErrorPage";
// import { UnderConstructionPage } from "./Pages/UnderConstructionPage";
import { CAMPAIGN_SLUG } from "./Utils/Constants";

const fetchDynamicSlug = () => {
  // Replace with logic to fetch dynamic slug from CMS
  return CAMPAIGN_SLUG;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to={`/${fetchDynamicSlug()}`} replace={true} />,
    errorElement: <ErrorPage code={404} />,
  },
  {
    path: ":slug",
    element: <Home index />,
    errorElement: <ErrorPage />,
  },
  {
    path: ":slug/contact",
    element: <Contact />,
    errorElement: <ErrorPage />,
  },
  {
    path: ":slug/guide",
    element: <Guide />,
    errorElement: <ErrorPage />,
  },
  {
    path: ":slug/participate",
    element: <Participate />,
    errorElement: <ErrorPage />,
  },
  {
    path: ":slug/products",
    element: <Products />,
    errorElement: <ErrorPage />,
  },
  {
    path: ":slug/products/:id",
    element: <ProductDetails />,
    errorElement: <ErrorPage />,
  },
  {
    path: ":slug/stores",
    element: <Stores />,
    errorElement: <ErrorPage />,
  },
  {
    path: ":slug/track",
    element: <Track />,
    errorElement: <ErrorPage />,
  },
  {
    path: ":slug/terms",
    element: <Terms />,
    errorElement: <ErrorPage />,
  },
  {
    path: ":slug/privacy",
    element: <Privacy />,
    errorElement: <ErrorPage />,
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
