import React from "react";
import { Link, useParams } from "react-router-dom";
import { PageContainer } from "../Components/Layout/PageContainer";
import { SectionContainer } from "../Components/Layout/SectionContainer";
import { FaGift } from "react-icons/fa6";
import { getHeaderFooter, getProductDetails } from "../Utils/Services";
import { LinkButton } from "../Components/Button/LinkButton";
import { getPath } from "../Utils/Helpers";

export default function ProductDetails() {
  const { slug, id } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isReady, setIsReady] = React.useState(false);
  const [hasPageError, setHasPageError] = React.useState(false);
  const [pageErrorCode, setPageErrorCode] = React.useState(null);
  const [campaign, setCampaign] = React.useState({});
  const [header, setHeader] = React.useState([]);
  const [footer, setFooter] = React.useState([]);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    const headerFooterRes = await getHeaderFooter(slug);
    const res = await getProductDetails(slug, id);

    if (res.status === 200) {
      setCampaign(headerFooterRes?.data?.campaign);
      setHeader(headerFooterRes?.data?.headers);
      setFooter(headerFooterRes?.data?.footers);
      setData(res?.data);
      setIsReady(true);
    } else {
      setHasPageError(true);
      setPageErrorCode(res.status);
    }
    setIsLoading(false);
  };

  return (
    <PageContainer
      isLoading={isLoading}
      isReady={isReady}
      slug={slug}
      campaign={campaign}
      header={header}
      footer={footer}
      hasPageError={hasPageError}
      pageErrorCode={pageErrorCode}
    >
      <SectionContainer containerClassName="py-32 flex flex-col md:flex-row justify-between gap-8">
        <div className="w-full max-w-full md:max-w-xl">
          <img
            className="w-full aspect-video object-contain object-center mx-auto"
            src={data.image}
            alt={data.model}
          />
        </div>
        <div className="w-full">
          <div className="space-y-5">
            <div className="space-y-4">
              <h1 className="text-2xl font-semibold text-gray-900">
                {data.title}
              </h1>
              <p className="text-base font-medium text-gray-500">
                Ref. {data.pn}
              </p>
            </div>
            <p className="text-3xl font-bold text-gray-900">RM{data.price}</p>
          </div>
          <hr className="mt-5 mb-8" />
          <div className="space-y-8">
            <div
              className="html-content text-base font-medium text-gray-500"
              dangerouslySetInnerHTML={{ __html: data.specification }}
            />
            {/* <ul className="list-disc ml-6">
              <li className="text-base font-medium text-gray-500">
                Windows 11 Pro
              </li>
              <li className="text-base font-medium text-gray-500">
                Intel® Core™ i5-1335U Processor
              </li>
            </ul> */}
            {/* TODO */}
            {data?.rewards?.length > 0 ? (
              <div className="space-y-2">
                <p className="text-base font-medium text-gray-900">Rewards</p>
                {data.rewards.map((item, index) => (
                  <div
                    key={index}
                    className="w-fit py-0.5 px-3 flex flex-row items-center gap-2 rounded-lg bg-primary-100"
                  >
                    <FaGift className="w-3 h-3 text-primary-800" />
                    <p className="text-sm font-medium text-primary-800">
                      {item}
                    </p>
                  </div>
                ))}
                <p className="text-xs text-gray-700">
                  *Rewards are on a first come first serve basis, terms &
                  conditions apply
                </p>
              </div>
            ) : null}
            <LinkButton
              primary
              label="Where to Buy"
              link={getPath(slug, "/stores")}
            />
          </div>
        </div>
      </SectionContainer>
    </PageContainer>
  );
}
